
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class List extends Vue {
  private active = this.$route.name || ''

  handleClick () {
    this.$router.replace({ name: this.active })
  }
}
